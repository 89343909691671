import React from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'

import Button from 'react-bootstrap/Button'
import Media from 'react-bootstrap/Media'
import Card from 'react-bootstrap/Card'

import Layout from '../components/layout'
import Testimonial from '../components/testimonial'

export default class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const starterKit = get(this, 'props.data.contentfulProduct')
    const testimonial = get(this, 'props.data.contentfulTestimonial')
    const kids = get(this, 'props.data.kids.childImageSharp.fixed')
    const blocks = get(this, 'props.data.blocks.childImageSharp.fixed')
    const woman = get(this, 'props.data.woman.childImageSharp.fixed')
    const caleb = get(this, 'props.data.caleb.childImageSharp.fixed')
    const lynn = get(this, 'props.data.lynn.childImageSharp.fixed')
    const metaDescription = `We make learning Math fun! Try our free PreK and elementary resources, videos, PDFs, and smart notebooks. Learn about the ${siteTitle} method today.`
    const structuredData = {
      '@context': 'http://schema.org',
      '@type': 'Organization',
      name: '4-group Math',
      url: 'https://www.4groupmath.com/',
      address: '10901 SE 26th St Bellevue WA, 98004 ',
      sameAs: [
        'https://www.facebook.com/4-group-Math-by-Kuske-Math-156107251068960/',
      ],
    }

    return (
      <Layout description={metaDescription}>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
        <Card className="m-2">
          <Media className="p-3">
            <Img
              fixed={blocks}
              alt="toy blocks"
              className="mission-img"
              style={{ width: '5rem', height: '5rem' }}
            />
            <Media.Body style={{ textAlign: 'center', marginRight: '1rem' }}>
              <h2>Our Mission</h2>
              <p className="lead m-0">
                To create a new generation of children who love math!
              </p>
            </Media.Body>
            <Img
              fixed={kids}
              alt="kids playing with blocks"
              className="mission-img"
              style={{ width: '5rem', height: '5rem' }}
            />
          </Media>
        </Card>

        <Card className="m-2">
          <Media className="p-3">
            <Media.Body className="d-flex flex-column align-items-center mx-4 text-center">
              <h2>Why {siteTitle}?</h2>
              <p style={{ width: '100%' }}>
                At {siteTitle} we know that children with a solid foundation in
                number sense find math easy and fun! The concept of number sense
                is as important to mathematics learning as phonemic awareness
                (or phonics) is to reading.
              </p>
              <Button
                variant="outline-primary mx-2"
                as={Link}
                to="/why/overview/"
              >
                Read the Overview
              </Button>
            </Media.Body>
          </Media>
        </Card>

        <Card className="m-2">
          <Link to={`/shop/${starterKit.slug}/`}>
            <Card.Img
              as={Img}
              alt={starterKit.images[0].title}
              fluid={starterKit.images[0].fluid}
              style={{ height: '10rem' }}
            />
          </Link>
          <Card.Body>
            <Card.Title as="h2">Getting Started</Card.Title>
            <p>
              The best way to get started with {siteTitle} is with our
              <Link to="/shop/starter-kit/"> Starter Kit</Link>.
            </p>
            <Button variant="primary m-2" as={Link} to="/shop/starter-kit/">
              Starter Kit Product Info
            </Button>
          </Card.Body>
        </Card>

        <Card className="m-2">
          <Card.Body>
            <Media className="indexTestimonial">
              <Img
                fixed={woman}
                style={{ width: '5rem', height: '5rem' }}
                alt="picture of lisa"
              />
              <Media.Body className="mx-3">
                <Testimonial node={testimonial} />
                <Button
                  as={Link}
                  to="/why/testimonials/"
                  variant="outline-primary mx-auto"
                >
                  Read More Testimonials
                </Button>
              </Media.Body>
            </Media>
          </Card.Body>
        </Card>

        <Card className="m-2">
          <Card.Body>
            <Media className="indexTestimonial">
              <Media.Body className="mx-3">
                <Card
                  as={Testimonial}
                  node={{
                    testimonial: {
                      childMarkdownRemark: {
                        rawMarkdownBody:
                          '“There is a belief out there that some people are inherently bad at math. Most of these people are visual, kinesthetic learners.  Success in math is determined by what and how you are taught.  4-group Math reaches all children through an innovative, hands on, visual model of number.  No more, “I hate math!”',
                      },
                    },
                    author: 'Lynn Kuske',
                    authorPosition: 'Founder',
                  }}
                />
              </Media.Body>
              <Img
                fixed={lynn}
                alt="Lynn Kuske"
                style={{ width: '5rem', height: '5rem' }}
              />
            </Media>
          </Card.Body>
        </Card>

        <Card className="shop-links">
          <Card.Body>
            <Card.Title as="h2">Shop</Card.Title>
            <p className="lead">
              Math is fun and hands-on with our colorful manipulatives!
            </p>
            <Button variant="primary m-2" as={Link} to="/shop/">
              Shop
            </Button>
          </Card.Body>
          <Card.Img
            as={Img}
            fixed={caleb}
            style={{
              width: '10rem',
              height: '10rem',
            }}
          />
        </Card>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    contentfulProduct(slug: { eq: "starter-kit" }) {
      slug
      price
      title
      checkoutLink
      images {
        title
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulTestimonial(id: { eq: "17a54625-7526-5cf5-bf09-79b4aa8679a7" }) {
      author
      authorPosition
      testimonial {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
    blocks: file(relativePath: { eq: "blocks-and-dice.jpg" }) {
      childImageSharp {
        fixed(width: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    kids: file(relativePath: { eq: "kids_playing.jpg" }) {
      childImageSharp {
        fixed(width: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    caleb: file(relativePath: { eq: "caleb3.jpeg" }) {
      childImageSharp {
        fixed(width: 200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    woman: file(relativePath: { eq: "testimonial.jpeg" }) {
      childImageSharp {
        fixed(width: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lynn: file(relativePath: { eq: "founders/lynn.jpg" }) {
      childImageSharp {
        fixed(width: 80, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
